export enum VehicleOrderManagerTestIds {
  HEADER = 'vehicle-order-manager__header',
  FILTER_TOOLBAR = 'vehicle-order-manager__filter-toolbar',
  TABLE = 'vehicle-order-manager__table'
}

export enum VehicleOrderAction {
  MODIFY = 'modify',
  CANCEL = 'cancel'
}
