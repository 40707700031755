import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VehicleOrder, VehicleOrderAPI } from '../types/vehicle-order.type';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { VehicleOrderAPIProps, VehicleOrderProps, VehicleOrderSection } from '../enums/vehicle-order.enum';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { NextInputValidators } from 'app/shared/validators/input.validator';
import { PatternConstant } from 'app/core/constants/pattern.constant';
import { FormConfig } from '@utils/models/Form';
import { NextDateHelper } from '@utils/core/next-date.helper';

@Injectable({
  providedIn: 'root'
})
export class VehicleOrderAdminService {
  constructor(private _fb: FormBuilder) {}

  buildVehicleOrderFormGroup(vehicleOrder?: VehicleOrder): FormGroup {
    const mainGroupValues = {
      [VehicleOrderSection.GENERAL_INFORMATION]: this._buildGeneralInformationGroup(
        NextObjectHelper.getPropertyFromObject(vehicleOrder, [VehicleOrderSection.GENERAL_INFORMATION], vehicleOrder)
      )
    };

    return this._fb.group(mainGroupValues);
  }

  formatCreateOrder(vehicleOrder: VehicleOrder): VehicleOrderAPI {
    return {
      [VehicleOrderAPIProps.ORDER_ID]: vehicleOrder[VehicleOrderProps.ORDER_ID],
      [VehicleOrderAPIProps.SOURCE_LOCATION]: vehicleOrder[VehicleOrderProps.SOURCE_LOCATION],
      [VehicleOrderAPIProps.ORDER_DATE]: vehicleOrder[VehicleOrderProps.ORDER_DATE],
      [VehicleOrderAPIProps.ORDER_AMOUNT]: vehicleOrder[VehicleOrderProps.ORDER_AMOUNT],
      [VehicleOrderAPIProps.DISTRIBUTOR_ID]: vehicleOrder[VehicleOrderProps.DISTRIBUTOR_ID],
      [VehicleOrderAPIProps.PRODUCT_LINE_ID]: vehicleOrder[VehicleOrderProps.PRODUCT_LINE],
      [VehicleOrderAPIProps.VEHICLE_MODEL]: vehicleOrder[VehicleOrderProps.VEHICLE_MODEL]
    };
  }

  private _buildGeneralInformationGroup(generalInfoValues = {}) {
    const { props, config } = this._buildGeneralInformationConfigValues();
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, generalInfoValues, config);

    return this._fb.group(formGroupState);
  }

  private _buildGeneralInformationConfigValues() {
    const groupProps = [
      VehicleOrderProps.ID,
      VehicleOrderProps.ORDER_DATE,
      VehicleOrderProps.ORDER_ID,
      VehicleOrderProps.DISTRIBUTOR_ID,
      VehicleOrderProps.PRODUCT_LINE,
      VehicleOrderProps.SOURCE_LOCATION,
      VehicleOrderProps.VEHICLE_MODEL,
      VehicleOrderProps.ORDER_AMOUNT
    ];

    const formConfig: FormConfig = {
      [VehicleOrderProps.ORDER_DATE]: {
        isRequired: true,
        defaultValue: NextDateHelper.buildAdjustedUTCDateISoString(new Date())
      },
      [VehicleOrderProps.ORDER_ID]: {
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPattern), NextInputValidators.maxLengthWithSpaces(20)],
        isRequired: true
      },
      [VehicleOrderProps.DISTRIBUTOR_ID]: {
        isRequired: true
      },
      [VehicleOrderProps.PRODUCT_LINE]: {
        isRequired: true
      },
      [VehicleOrderProps.SOURCE_LOCATION]: {
        isRequired: true
      },
      [VehicleOrderProps.VEHICLE_MODEL]: {
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPattern), NextInputValidators.maxLengthWithSpaces(50)],
        isRequired: true
      },
      [VehicleOrderProps.ORDER_AMOUNT]: {
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars), NextInputValidators.maxLengthWithSpaces(15)],
        isRequired: true
      }
    };

    return { props: groupProps, config: formConfig };
  }
}
