import { NextRouteItem } from '../../core/types/route.type';
import { NextRouteProps } from '../../core/enums/route.enum';
import { NavItemId } from '../../shared/enums/navigation.enum';

export class NewBusinessRouteConfig {
  static readonly mainPath = 'new-business';

  static readonly mainRoute: NextRouteItem = {
    [NextRouteProps.ID]: NavItemId.NEW_BUSINESS,
    [NextRouteProps.LABEL]: 'NEW_BUSINESS.TITLE',
    [NextRouteProps.PATH]: NewBusinessRouteConfig.mainPath,
    [NextRouteProps.DISABLED_BREADCRUMB_ITEM]: true
  };
}
