export enum VehicleOrderProps {
  ID = 'id',
  ORDERS = 'orders',
  ORDER_DATE = 'orderCreatedDate',
  PROCESSED_DATE = 'processedDate',
  ORDER_NUMBER = 'orderNumber',
  DISTRIBUTOR_ID = 'distributorId',
  DISTRIBUTOR_NAME = 'distributorName',
  DISTRIBUTOR_GROUP = 'distributorGroup',
  PRODUCT_LINE = 'productLine',
  SOURCE_LOCATION = 'sourceLocalCode',
  COUNTRY_ACCOUNT_MANAGER = 'countryAccountManager',
  VEHICLE_MODEL = 'vehicleModel',
  ORDER_AMOUNT = 'orderAmount',
  ORDER_STATUS = 'orderStatus',
  ORDER_CURRENCY = 'orderCurrency',
  ORDER_ID = 'wwtfOrderId'
}

export enum VehicleOrderAPIProps {
  ORDER_ID = 'wwtfOrderId',
  SOURCE_LOCATION = 'sourceLocalCode',
  ORDER_DATE = 'orderCreatedDate',
  ORDER_AMOUNT = 'orderAmount',
  ORDER_CURRENCY = 'orderCurrency',
  DISTRIBUTOR_ID = 'distributorId',
  PRODUCT_LINE_ID = 'productLineId',
  VEHICLE_MODEL = 'vehicleModel'
}

export enum VehicleOrderFilterProps {
  DISTRIBUTOR_ID = 'distributorId',
  ORDER_ID = 'wwtfOrderId',
  ORDER_DATE_FROM = 'orderCreatedDateFrom',
  ORDER_DATE_TO= 'orderCreatedDateTo',
  PRODUCT_LINE = 'productLine',
  VEHICLE_MODEL = 'vehicleModel',
  ORDER_CURRENCY = 'orderCurrency',
  ORDER_STATUS = 'orderStatus'
}

export enum OrderStatus {
  'ACTIVE' = 'ACTIVE'
}

export enum VehicleOrderSection {
  GENERAL_INFORMATION = 'generalInformation'
}

export enum ModifyOrderProps {
  TARGET_DISTRIBUTOR = 'targetDistributor',
  TARGET_PRODUCT_LINE = 'targetProductLine'
}

export enum VehicleOrderTotalProps {
  COUNT = 'count',
  TOTAL = 'total',
  CURRENCY = 'currency'
}

export enum VehicleOrderApiProps {
  VEHICLE_ORDER = 'vehicleOrder'
}

export enum VehicleOrderResponseProps {
  RESPONSE = 'orderCreationResponse',
  ID = 'id',
  STATUS = 'wwtfStatus'
}
