import { NavItemId } from '../../../../shared/enums/navigation.enum';
import { NextRouteItem } from '../../../../core/types/route.type';
import { NewBusinessRouteConfig } from '@pages/new-business/new-business.route.config';
import { NextRouteProps, RouteParams } from '../../../../core/enums/route.enum';
import { BaseRouteConfig } from 'app/core/constants/base.route.config';

export class VehicleOrderRouteConfig {
  static readonly mainPath = 'vehicle-order-manager';
  static readonly vehicleOrderPath = 'vehicle-order';
  static readonly detailsPath = 'details';

  static readonly mainRoute: NextRouteItem = {
    [NextRouteProps.ID]: NavItemId.VEHICLE_ORDER,
    [NextRouteProps.LABEL]: 'VEHICLE_ORDER.TITLE',
    [NextRouteProps.PATH]: VehicleOrderRouteConfig.mainPath,
    [NextRouteProps.PARENT]: NewBusinessRouteConfig.mainRoute
  };

  // Add New Vehicle Order
  static readonly newVehicleOrderConfig: NextRouteItem = {
    id: 'vehicleOrderForm',
    label: 'VEHICLE_ORDER.ACTIONS.ADD_VEHICLE_ORDER',
    path: VehicleOrderRouteConfig.vehicleOrderPath,
    parent: VehicleOrderRouteConfig.mainRoute,
    disabledBreadcrumbItem: true
  };

  static readonly newVehicleOrderFormId: NextRouteItem = {
    id: 'vehicleOrderFormId',
    path: `:${RouteParams.ID}`,
    pathParam: `${RouteParams.ID}`,
    fromUrlParamPath: true,
    parent: VehicleOrderRouteConfig.newVehicleOrderConfig,
    disabledBreadcrumbItem: true
  };
  // Nav Sections
  static readonly vehicleOrderFormStep: NextRouteItem = {
    ...BaseRouteConfig.sectionBase,
    id: 'formStep',
    parent: VehicleOrderRouteConfig.newVehicleOrderFormId
  };

  static readonly detailsConfig: NextRouteItem = {
    id: 'vehicleOrderDetails',
    label: 'BREADCRUMBS.DETAILS',
    path: VehicleOrderRouteConfig.detailsPath,
    parent: [VehicleOrderRouteConfig.mainRoute],
    disabledBreadcrumbItem: true
  };

  static readonly detailsId: NextRouteItem = {
    id: 'vehicleOrderDetailsId',
    path: `:${RouteParams.ID}`,
    pathParam: `${RouteParams.ID}`,
    fromUrlParamPath: true,
    disabledBreadcrumbItem: true,
    parent: VehicleOrderRouteConfig.detailsConfig
  };

  static readonly detailsTabStep: NextRouteItem = {
    ...BaseRouteConfig.sectionBase,
    parent: VehicleOrderRouteConfig.detailsId
  };
}
