export class VehicleOrderConstantMock {
    static orderStatus = [
        {
            'status': 'VEHICLE BOOKING FINALIZED'
        },
        {
            'status': 'RELEASED FROM ASSEMBLY PLANT'
        },
        {
            'status': 'ALLOCATION AND FINANCE RELEASED'
        },
        {
            'status': 'ORDER RECEIVED: ERROR'
        },
        {
            'status': 'VEHICLE BUCKED'
        }
    ];

    static productLine = [
        {
            'name': 'PL345A'
        },
        {
            'name': 'PL486E'
        },
        {
            'name': 'PL996O'
        }
    ];

    static sourceLocation = [
        {
            'name': 'FDEB'
        },
        {
            'name': 'FGBB'
        }
    ];

    static vehicleType = [
        {
            'name': 'Vehículo'
        },
        {
            'name': 'Parts'
        },
        {
            'name': 'Last Interest'
        },
        {
            'name': 'Extension'
        }
    ];
}
 