import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { InputNumberType } from '@components/atoms/form-inputs/enums/input.enum';
import { SourceLocationService } from '@pages/distributor/services/source-location.service';
import { VehicleOrderConstant } from '@pages/new-business/pages/vehicle-order/constants/vehicle-order.constant';
import { VehicleOrderProps } from '@pages/new-business/pages/vehicle-order/enums/vehicle-order.enum';
import { VehicleOrderService } from '@pages/new-business/pages/vehicle-order/services/vehicle-order.service';
import { merge, Observable } from 'rxjs';
import { DistributorService } from '@pages/distributor/services/distributor.service';
import { CreditProps } from '@pages/distributor/enums/credit.enum';
import { SCLProps } from '@pages/distributor/constant/securities-credit-limits.enum';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';

@Component({
  selector: 'vehicle-order-general-information',
  templateUrl: './vehicle-order-general-information.component.html',
  styleUrl: './vehicle-order-general-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleOrderGeneralInformationComponent extends GenericBaseComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() currency?: string;
  
  productLineList: DropdownList;
  sourceLocation$: Observable<DropdownList>;
  vehicleType$: Observable<DropdownList>;
  distributorList$: Observable<DropdownList>;
  distributorIdControl: any;
  sourceLocationIdControl: any;

  protected readonly VehicleOrderProps = VehicleOrderProps;
  protected readonly InputNumberType = InputNumberType;
  protected readonly VehicleOrderConstant = VehicleOrderConstant;
  protected readonly CreditProps = CreditProps;
  protected readonly SCLProps = SCLProps;

  constructor(
    private _vehicleOrderService: VehicleOrderService,
    private _sourceLocationService: SourceLocationService,
    private _distributorService: DistributorService
  ) {
    super();
  }

  ngOnInit(): void {
    this._initializeFormConfig();
    this._formChangeEvents();
  }

  private _initializeFormConfig() {
    this.sourceLocation$ = this._sourceLocationService.getDropdownList();
    //this.distributorList$ = this._distributorService.getDropdownList();
    this.distributorIdControl = this.formGroup.get(VehicleOrderProps.DISTRIBUTOR_ID)?.value;
    this.sourceLocationIdControl = this.formGroup.get(VehicleOrderProps.SOURCE_LOCATION)?.value;
  }

  private _formChangeEvents() {
    const distributorIdControl = this.formGroup.get(VehicleOrderProps.DISTRIBUTOR_ID);
    const sourceLocationIdControl = this.formGroup.get(VehicleOrderProps.SOURCE_LOCATION);
    const productLineIdControl = this.formGroup.get(VehicleOrderProps.PRODUCT_LINE);

    if (productLineIdControl?.value && distributorIdControl?.value && sourceLocationIdControl?.value) {
      this._distributorService
        .getProductLineByDistributorAndSourceLocation(distributorIdControl.value, sourceLocationIdControl.value)
        .subscribe(productLineList => {
          this.productLineList = productLineList;
      });
    }

    const distributorChangesSub = merge(distributorIdControl!.valueChanges, sourceLocationIdControl!.valueChanges, productLineIdControl!.valueChanges).subscribe(() => {
      if (distributorIdControl?.value && sourceLocationIdControl?.value) {
        if (!productLineIdControl?.value) {
          this._distributorService
          .getProductLineByDistributorAndSourceLocation(distributorIdControl.value, sourceLocationIdControl.value)
          .subscribe(productLineList => {
            this.productLineList = productLineList;
          });
        } else {
          this.productLineList = [];
            if (this.sourceLocationIdControl !== sourceLocationIdControl?.value && this.distributorIdControl === distributorIdControl?.value) {
              this._distributorService
                .getProductLineByDistributorAndSourceLocation(distributorIdControl.value, sourceLocationIdControl.value)
                .subscribe(productLineList => {
                  this.productLineList = productLineList;
              });
            } else {
              this.distributorIdControl = distributorIdControl?.value;
              this.sourceLocationIdControl = sourceLocationIdControl?.value;
              this._distributorService
                .getProductLineByDistributorId(distributorIdControl.value)
                .subscribe(productLineList => {
                  this.productLineList = productLineList;
              });
            }
        }
      } else if (distributorIdControl?.value && productLineIdControl?.value && !sourceLocationIdControl?.value) {
        const sourceLocationCode = productLineIdControl?.value.trim().split('-')[0];
        this.formGroup.get(VehicleOrderProps.SOURCE_LOCATION)?.setValue(sourceLocationCode)
      } else if (distributorIdControl?.value) {
        this._distributorService
          .getProductLineByDistributorId(distributorIdControl.value)
          .subscribe(productLineList => {
            this.productLineList = productLineList;
          });
      } else {
        this.productLineList = [];
      }
    });
    this.subs.push(distributorChangesSub);
  }
}
